import { createSlice } from "@reduxjs/toolkit";

export const jobsStore = createSlice({
  name: "jobs",
  initialState: {
    addNode: false,
    nodes: [],
    edges: [],
    configurations: [],
    updateNode: null,
    updateJobs: false,
    dependentNode: null,
    selectedEdge: null,
    selectedJob: null,
    editMode: false,
    jobLists: [],
    jobsRunning: false,
    jobNotification: {},
    backButtonRoute: "data",
    tabValue: "1",
    jobId: null,
    runTaskId: null,
  },
  reducers: {
    setAddNode: (state, action) => {
      if ("addNode" in action.payload) {
        state.addNode = action.payload;
      }
      if ("nodes" in action.payload) {
        state.nodes = action.payload;
      }
      if ("edges" in action.payload) {
        state.edges = action.payload;
      }
    },
    setConfigurations: (state, action) => {
      if ("configurations" in action.payload) {
        state.configurations = action.payload;
      }
    },
    setUpdateNode: (state, action) => {
      if ("updateNode" in action.payload) {
        state.updateNode = action.payload;
      }
    },
    setUpdateJobs: (state, action) => {
      if ("updateJobs" in action.payload) {
        state.updateJobs = action.payload;
      }
      if ("jobNotification" in action.payload) {
        state.jobNotification = action.payload;
      }
    },
    setDependentNode: (state, action) => {
      if ("dependentNode" in action.payload) {
        state.dependentNode = action.payload;
      }
    },
    setSelectedEdge: (state, action) => {
      if ("selectedEdge" in action.payload) {
        state.selectedEdge = action.payload;
      }
    },
    setSelectedJob: (state, action) => {
      if ("selectedJob" in action.payload) {
        state.selectedJob = action.payload;
      }
      if ("editMode" in action.payload) {
        state.editMode = action.payload;
      }
    },
    setJobLists: (state, action) => {
      if ("jobLists" in action.payload) {
        state.jobLists = action.payload;
      }
    },
    setJobRunning: (state, action) => {
      if ("jobsRunning" in action.payload) {
        state.jobsRunning = action.payload;
      }
    },
    setBackButtonRoute: (state, action) => {
      state.backButtonRoute = action.payload;
    },
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    setJobId: (state, action) => {
      state.jobId = action.payload;
    },
    setRunTaskId: (state, action) => {
      if ("runTaskId" in action.payload) {
        state.runTaskId = action.payload;
      }
    },
  },
});

export const {
  setAddNode,
  setConfigurations,
  setUpdateNode,
  setUpdateJobs,
  setDependentNode,
  setSelectedEdge,
  setSelectedJob,
  setJobLists,
  setJobRunning,
  setBackButtonRoute,
  setTabValue,
  setJobId,
  setRunTaskId,
} = jobsStore.actions;

export default jobsStore.reducer;
