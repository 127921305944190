import React from "react";
import { Select } from "antd";
import styled from "styled-components";
import theme from "../../styles/theme";
import {
  SelectSizes,
  getBackgroundColor,
  getFontSize,
  getHeight,
  getTextColor,
} from "./helper";
import Typography from "../typography";
import NextImage from "../image";
import { commonIcons } from "@/constants/icons/common";

type SelectProps = {
  value?: string | any;
  size?: SelectSizes;
  options?: object;
  label?: string;
  inactive?: boolean;
  placeholder?: string;
  error?: string;
  tooltipMessage?: string;
  description?: string;
  margin?: string;
  width?: string;
  showSearch?: boolean;
  onChange: (value: any) => void;
  defaultValue?: string;
  labelInValue?: boolean;
  mandatory?: boolean;
  loading?: boolean;
  mode?: string;
};

const StyledSelect = styled(Select)<SelectProps>(
  ({
    size = "sm",
    error = "",
    inactive = false,
    value = "",
    margin,
    width,
  }) => ({
    fontSize: getFontSize(size),
    fontWeight: theme.fontWeightRegular,
    height: getHeight(size),
    width: width ? width : "100%",
    background: getBackgroundColor(inactive, error),
    borderRadius: theme.inputBorderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none !important",
    margin: margin ? margin : 0,
    color: getTextColor(value, inactive),
  }),
);

const FlexDiv = styled.div`
  display: flex;
  gap: 10px;
  alignitems: center;
`;

const AntSelect: React.FC<SelectProps> = ({
  inactive = false,
  options = [],
  placeholder = "Select",
  value,
  size,
  label,
  error,
  tooltipMessage = "",
  description,
  onChange,
  showSearch,
  defaultValue,
  labelInValue = false,
  mandatory,
  loading,
  mode,
  ...rest
}) => {
  const handleChange = (e: string) => {
    onChange(e);
  };
  const filterOption = (
    input: string,
    option: { label: string; value: string; name: string },
  ) => {
    if (option.name) {
      return option.name.toLowerCase().includes(input.toLowerCase());
    }
    return option.label.toLowerCase().includes(input.toLowerCase());
  };

  const Wrapper = styled("div")<{ width?: string; margin?: string }>`
    width: ${(props) => (props.width ? props.width : "100%")};
    margin: ${(props) => (props.margin ? props.margin : 0)};
  `;
  return (
    <Wrapper>
      {label && (
        <FlexDiv>
          <Typography.P2 margin="0 0 8px">
            {label}
            {mandatory && <span style={{ color: theme.error600 }}>*</span>}
          </Typography.P2>
          {tooltipMessage && (
            <NextImage
              src={commonIcons.info}
              alt={commonIcons.info}
              height={16}
              width={16}
              tooltip={tooltipMessage}
            />
          )}
        </FlexDiv>
      )}
      {description && (
        <Typography.P3 color={theme.neutral900} margin="0 0 8px 0">
          {description}
        </Typography.P3>
      )}
      <StyledSelect
        {...rest}
        size={size}
        disabled={inactive}
        placeholder={placeholder || "Select"}
        value={value}
        onChange={handleChange}
        filterOption={filterOption as any}
        error={error}
        options={options}
        showSearch={showSearch}
        defaultValue={defaultValue}
        labelInValue={labelInValue}
        loading={loading}
        mode={mode}
      />
      {error && (
        <Typography.P3
          weight={theme.fontWeightRegular}
          margin="8px 0 0"
          color={theme.error600}
        >
          {error}
        </Typography.P3>
      )}
    </Wrapper>
  );
};
export default AntSelect;
