export const IDEIcons: { [key: string]: string } = {
  abortQuery: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/abort-query.svg`,
  activeFilter: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/active-filter.svg`,
  addFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/add-file.svg`,
  addFileTab: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/add-file-tab.svg`,
  addFolder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/add-folder.svg`,
  arrowRightDark: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/ArrowRight.svg`,
  closeFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/close-file.svg`,
  collapse: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/collapse.svg`,
  collapsedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/collapsed.svg`,
  copyIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/copy-icon.svg`,
  dbIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/db-icon.svg`,
  dbIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/db-ide.svg`,
  dbIdeInactive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/database-inactive.svg`,
  downloadResults: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/download-results.svg`,
  enabledTabs: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/enabledTabs.svg`,
  errorCircle: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/error-circle.svg`,
  expand: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/expand.svg`,
  expandedIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/expanded.svg`,
  fileTab: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/file-tab.svg`,
  folderClosed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/folder-closed.svg`,
  folderIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/folder-ide.svg`,
  folderIdeActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/folder-active.svg`,
  folderOpen: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/folder-open.svg`,
  formatSql: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/format-sql.svg`,
  gitIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/git-ide.svg`,
  greenDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/green-dot.svg`,
  inactiveFilter: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/inactive-filter.svg`,
  inactiveSort: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/inactive-sort.svg`,
  newFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/new-file.svg`,
  newFolder: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/new-folder.svg`,
  pauseBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/PauseBlue.svg`,
  pinned: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/pinned.svg`,
  placeholderIde: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/placeholder-ide.svg`,
  playBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/PlayBlue.svg`,
  plugBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/PlugChargingBlue.svg`,
  redDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/red-dot.svg`,
  refreshIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/refresh-datasource.svg`,
  refreshWhite: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/refreshWhite.svg`,
  reloadWorkspace: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/reloadWorkspace.svg`,
  reloadConnector: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/Reload.svg`,
  role: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/role.svg`,
  rowsCount: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/rows-count.svg`,
  run: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/run.svg`,
  sad: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/sad.svg`,
  schemaIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/schema-icon.svg`,
  scriptRunning: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/script-running.gif`,
  search: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/search-input.svg`,
  selectedUser: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/selected-user.svg`,
  selectionResults: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/selection-results.svg`,
  settingsNormal: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/settingsnormal.svg`,
  sortDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/sort-down.svg`,
  sortUp: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/sort-up.svg`,
  sqlFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/sql-file.svg`,
  startOver: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/start-over.svg`,
  stop: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/stop.svg`,
  sync: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/sync.svg`,
  syncBlue: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/SyncNowBlue.svg`,
  synced: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/synced.svg`,
  tableIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/table-icon.svg`,
  tabsIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/tabs-icon.svg`,
  viewIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/view-icon.svg`,
  warehouse: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/warehouse.svg`,
  whiteDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/white-down.svg`,
  warningDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/warningDot.svg`,
  repoChangesGif: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/repo-changes-gif.gif`,
  mainBranchLock: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/main-branch-lock.svg`,
  caretLeft: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/caret-right.svg`,
  yellowDot: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/yellow-dot.svg`,
  caretDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/care-down-icon.svg`,
  noGitChanges: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/no-git-changes.svg`,
  backCommit: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/back-commit.svg`,
  errorTerminal: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/error-terminal.svg`,
  aheadArrowUp: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/ahead-arrow-up.svg`,
  behindArrowDown: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/behind-arrow-down.svg`,
  abortIcon: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/abort-icon.svg`,
  threeDotMenu: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/DotsThree.svg`,
  abortCommand: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/abort-command.svg`,
  copyActive: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/copy-active.svg`,
  copySuccess: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/copy-sucess.svg`,
  deleteBranch: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/delete-branch.svg`,
  packages: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/packages.svg`,
  packagesError: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/packagesError.svg`,
  packagesFailed: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/packagesFailed.svg`,
  pythonFile: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/pythonFile.svg`,
  pythonLib: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}ide/pythonLib.svg`,
};
